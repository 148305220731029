<template>
  <RevForm
    :initial-values="formValues"
    :name="formId"
    :validate="validate"
    @submit="(values) => emit('submit', values)"
  >
    <template #default="{ values, errors }">
      <DynamicAddressFieldValidatorsErrorLogger
        v-if="props.activateAddressValidationErrorLogger"
        :errors="errors"
        :form-values="values"
        logger-scope="TRADEIN_ADDRESS_FORM"
      />
      <div class="flex flex-col gap-10">
        <div :class="['grid', 'gap-8', TOTALCOLS, ...extraCss]">
          <!-- Prevent prettier to remove parentheses from v-model type cast -->
          <!-- prettier-ignore -->
          <div
            v-for="input in formConfig"
            :key="input.id"
            :class="getColsByScreen(input.cols)"
          >
             <RevInputText
              v-if="input.type === 'text'"
              :id="input.id"
              v-model="(values[input.id] as InputValue)"
              :class="input.extraCss"
              :description="input?.description"
              :disabled="input.disabled"
              :error="errors[input.id]"
              :format="input?.formatter"
              :label="input.label"
            />

            <RevInputNumber
              v-if="input.type === 'number'"
              :id="input.id"
              v-model="(values[input.id] as number | undefined)"
              :class="input.extraCss"
              :description="input?.description"
              :disabled="input.disabled"
              :error="errors[input.id]"
              :label="input.label"
              :max="input?.max"
              :min="input?.min"
              :show-stepper="false"
            />

            <RevInputSelect
              v-if="input.type === 'select'"
              :id="input.id"
              v-model="(values[input.id] as Value)"
              :class="input.extraCss"
              :disabled="input.disabled"
              :error="errors[input.id]"
              :label="input.label"
              :options="input.options"
            />

           <div v-if="input.type === 'checkbox'">
              <RevCheckbox
                :id="input.id"
                v-model="
                  (values[input.id] as CheckboxValue)
                "
                :class="input.extraCss"
                :variant="input.variant"
              >
                <template #label> 

                   <FormattedMessage
v-if="input.formattedMessage"
                          :definition="input.formattedMessage.definition"
                        >
                          <template #[input.formattedMessage.id]>
                            <RevLink
                              target="_blank"
                              :to="input.formattedMessage.route"
                            >
                              {{ input.formattedMessage.label}}
                            </RevLink>
                          </template>
                  </FormattedMessage>
                  
                  <span v-else>
                    {{ input.label }}
                  </span>
                
                </template>
                <template #description v-if="input.description">
                  {{ input.description }}
                </template>
              </RevCheckbox>
              <p v-if="errors[input.id]" class="text-static-danger-hi body-2 mt-8 flex items-baseline peer-disabled:text-static-default-low-disabled">{{ errors[input.id] }}</p>
            </div>

            <RevInputDate
              v-if="input.type === 'date'"
              :id="input.id"
              v-model:model-value="(values[input.id] as InputValue)"
              :description="input.description"
              :disabled="input.disabled"
              :error="errors[input.id]"
              :label="input.label"
            />

            <RevInputPhone
              v-if="input.type === 'phone'"
              :id="input.id"
              v-model="(values[input.id] as string)"
              :class="input.extraCss"
              :clear-button-aria-label="input.clearBtnLabel"
              :default-country="input.country || country"
              :description="input.description"
              :disabled="input.disabled"
              :error="errors[input.id]"
              :label="input.label"
            />

            <AddressAutocomplete
              v-if="input.type === 'address-autocomplete'"
              :id="input.id"
              v-model="(values[input.id] as string)"
              autocomplete="off"
              :class="input.extraCss"
              :country="input.country || country"
              :description="input.description"
              :error="errors[input.id]"
              :feature-code="FeatureCode.WEB_BUYBACK_ENTER_ADDRESS"
              :label="input.label"
              listbox-placement="bottom"
              type="text"
              @select-item="handleSearchSelectItem"
            />
            
            <div v-if="input.type === 'radio-group'" class="mt-16 flex flex-col gap-16">
              <RevRadio
                v-for="option in input.options"
                :id="option.value"
                :key="option.value"
                v-model="(values[input.id] as string)"
                :class="input.extraCss"
                :disabled="option.disabled"
                :value="option.value"
                variant="full"
              >
              <template #label>{{ option.label }}</template>
              <template #description>
                {{ option.description }}
              </template>
              </RevRadio>
              <p v-if="errors[input.id]" class="text-static-danger-hi body-2 mt-8 flex items-baseline peer-disabled:text-static-default-low-disabled">{{ errors[input.id] }}</p>
            </div>

            <div v-if="input.type === 'file'">
              <RevInputFileUpload
                :id="input.id"
                v-model="(values[input.id] as File[])"
                :accept="input.acceptedExtensions"
                :class="input.extraCss"
                :data-test="input.id"
                :description="errors[input.id] ? '' : input.description"
                :error="errors[input.id]"
                :label-empty="input.labelEmpty"
                :max-file-size="MAX_FILE_SIZE"
                :max-files="MAX_FILES"
                :min-file-size="MIN_FILE_SIZE"
                @errors="(errors) => input.handleErrors(errors)"
              />
              <p v-if="errors[input.id]" class="text-static-danger-hi body-2 mt-8 flex items-baseline peer-disabled:text-static-danger-hi-disabled">
                  {{ errors[input.id] }}
              </p>
            </div>
          </div>
        </div>
        <RevButton
          v-if="props.hasSubmitButton"
          class="ml-auto mt-10"
          :data-qa="props.submitButtonId"
          :disabled="isLoading"
          full-width="responsive"
          :loading="isLoading"
          type="submit"
          variant="primary"
        >
          {{ props.submitButtonLabel }}
        </RevButton>
      </div>
    </template>
  </RevForm>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import type { RouteLocationRaw } from 'vue-router'

import type { MarketCountryCode } from '@backmarket/http-api'
import AddressAutocomplete from '@backmarket/nuxt-module-address/AddressAutocomplete.vue'
import DynamicAddressFieldValidatorsErrorLogger from '@backmarket/nuxt-module-address/DynamicAddressFieldValidatorsErrorLogger.vue'
import { type Address } from '@backmarket/nuxt-module-address/address'
import { FeatureCode } from '@backmarket/nuxt-module-address/featureCode'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { insertIf } from '@backmarket/utils/collection/insertIf'
import { RevButton } from '@ds/components/Button'
import { RevCheckbox } from '@ds/components/Checkbox'
import { VariantsEnum } from '@ds/components/Checkbox/Checkbox.constant'
import { RevForm, type Validator, makeValidate } from '@ds/components/Form'
import { setFormValues } from '@ds/components/Form/Form.actions'
import { RevInputDate } from '@ds/components/InputDate'
import { RevInputFileUpload } from '@ds/components/InputFileUpload'
import type { FileError } from '@ds/components/InputFileUpload/InputFileUpload.type'
import { RevInputNumber } from '@ds/components/InputNumber'
import { RevInputPhone } from '@ds/components/InputPhone'
import {
  type Option,
  RevInputSelect,
  type Value,
} from '@ds/components/InputSelect'
import { RevInputText } from '@ds/components/InputText'
import { RevLink } from '@ds/components/Link'
import { RevRadio } from '@ds/components/Radio'
import type { InputValue } from '@ds/types/InputValue'

import {
  MAX_FILES,
  MAX_FILE_SIZE,
  MIN_FILE_SIZE,
} from '~/scopes/dashboard/my-sales/components/Modals/KycDetails/identity/Identity.utils'

type Cols =
  | {
      mobile: number
      desktop: number
    }
  | number

export interface InputBase {
  id: string
  cols: Cols
  label: string
  description?: string
  disabled?: boolean
  validation?: Array<
    (
      arg1: string,
      arg2: FormValues,
    ) => boolean | string | number | Validator<FormValues>
  >
  extraCss?: Array<string>
}

export type CheckboxValue = boolean | string[] | undefined

export interface InputText extends InputBase {
  type: 'text'
  formatter?: (inputEvent: InputValue) => InputValue
  variant?: 'default' | 'tiny'
  value: InputValue
}

export interface InputNumber extends InputBase {
  type: 'number'
  value: InputValue
  min?: number
  max?: number
}

export interface InputCheckbox extends InputBase {
  type: 'checkbox'
  value: CheckboxValue
  formattedMessage?: {
    definition: I18nDefinition<string>
    id: string
    label: string
    route: RouteLocationRaw
  }
  variant?: VariantsEnum
}

export interface InputDate extends InputBase {
  type: 'date'
  value: InputValue
}

export interface InputPhone extends InputBase {
  type: 'phone'
  value: string
  description: string
  country?: MarketCountryCode
  clearBtnLabel: string
}

export interface InputSelect extends InputBase {
  type: 'select'
  options: Array<Option>
  value: string
}

export interface InputAddressAutocomplete extends InputBase {
  type: 'address-autocomplete'
  country?: MarketCountryCode
  value: string
}

export interface InputRadioGroup
  extends Omit<InputBase, 'label' | 'description' | 'disabled'> {
  type: 'radio-group'
  value: string | boolean
  options: {
    value: string
    label: string
    description: string
    disabled?: boolean
  }[]
}

export interface InputFile extends InputBase {
  type: 'file'
  acceptedExtensions: Array<string>
  labelEmpty: string
  value?: File[]
  handleErrors: (errors: FileError[]) => void
}

export type Input =
  | InputText
  | InputNumber
  | InputCheckbox
  | InputSelect
  | InputDate
  | InputPhone
  | InputAddressAutocomplete
  | InputRadioGroup
  | InputFile

interface FormGeneratorProps {
  /**
   * formConfig contains all form inputs
   * 1 or many elements per row
   * Total available columns per row is 6
   * E.g: for 2 equal cols in a row, both of columns should have cols:3
   */
  formConfig: Array<Input>
  submitButtonLabel?: string
  submitButtonId?: string
  hasSubmitButton?: boolean
  activateAddressValidationErrorLogger?: boolean
  formId: string
  isLoading?: boolean
  extraCss?: Array<string>
}

export type FormValues = Record<string, unknown>

const country = useMarketplace()?.market?.countryCode

/** Total number of columns in a row */
const TOTALCOLS = 'grid-cols-6'

const props = withDefaults(defineProps<FormGeneratorProps>(), {
  formConfig: () => [],
  submitButtonLabel: '',
  submitButtonId: '',
  hasSubmitButton: true,
  isLoading: false,
  extraCss: () => [],
})

const emit = defineEmits<{
  (event: 'submit', payload: FormValues): void
}>()

const formValues = ref<FormValues>(
  props.formConfig.reduce((acc, item) => {
    return {
      ...acc,
      [item.id]: item.value,
    }
  }, {}),
)

const hasStateOrProvinceInput = props.formConfig.some(
  (input) => input.id === 'stateOrProvince',
)

const validate = makeValidate<FormValues>(
  props.formConfig.reduce((acc, input) => {
    if (!input.validation) {
      return acc
    }

    return {
      ...acc,
      [input.id]: input.validation,
    }
  }, {}),
)
/**
 * Tailwind css does not recommand constructing class names dynamically
 * Note: Every line has 6 columns as it is defined in TOTALCOLS constant
 * See https://tailwindcss.com/docs/content-configuration#dynamic-class-names
 */
function getColsByScreen(cols: Cols): Array<string> {
  const classNames: Array<string> = []

  function getClassNameByCols(nbCols: number): string {
    switch (nbCols) {
      case 1:
        return 'col-span-1'
      case 2:
        return 'col-span-2'
      case 3:
        return 'col-span-3'
      case 4:
        return 'col-span-4'
      case 5:
        return 'col-span-5'
      case 6:
        return 'col-span-6'
      default:
        break
    }

    return ''
  }

  if (typeof cols === 'number') {
    classNames.push(getClassNameByCols(cols))
  }

  if (typeof cols === 'object') {
    classNames.push(getClassNameByCols(cols.mobile))

    switch (cols.desktop) {
      case 1:
        classNames.push('md:col-span-1')
        break
      case 2:
        classNames.push('md:col-span-2')
        break
      case 3:
        classNames.push('md:col-span-3')
        break
      case 4:
        classNames.push('md:col-span-4')
        break
      case 5:
        classNames.push('md:col-span-5')
        break
      case 6:
        classNames.push('md:col-span-6')
        break
      default:
        break
    }
  }

  return classNames
}

function handleSearchSelectItem(address: Address) {
  const { city, postalCode, stateOrProvince, street, street2 } = address

  const values = {
    city: city || '',
    postalCode: postalCode || '',
    street: street || '',
    street2: street2 || '',
    ...insertIf(Boolean(stateOrProvince) && hasStateOrProvinceInput, {
      stateOrProvince,
    }),
  }

  setFormValues(props.formId, values)
}
</script>
